import Space from '@/components/atoms/Space'
import SelectSitesModal from '@/components/molecules/Modals/SelectSitesModal'
import ReportingYearSelector from '@/components/molecules/SelectorGroup/reportingYearSelect'
import AntiWarmingTable from '@/components/template/Reporting/AntiWarming/Table/AntiWarmingTable'
import { withLayout } from '@/components/template/Layout'
import {
    AnnualOrganizationSummary,
    AntiWarmingReport,
    HalfYearOrganizationSummary,
    MonthlyOrganizationSummary,
    QuarterlyOrganizationSummary,
} from '@/openapi/api'
import { getAntiWarmingReport } from '@/services/store'
import caches from '@/utils/cache'
import { getTime } from '@/utils/nendo'
import useStore from '@/zustand/sotre'
import * as FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { KEY_LOCALSTORAGE } from '@/constant/text'
import { YEAR_OPTIONS } from '@/constant/options'

export type CompareResponseData = AnnualOrganizationSummary | HalfYearOrganizationSummary | QuarterlyOrganizationSummary

export type ResponseData = CompareResponseData | MonthlyOrganizationSummary

// ====================================================================================
// Overview
// ====================================================================================

const OverviewInner = () => {
    const { expandedMenu, setMessage } = useStore()
    const [csvData, setCsvData] = useState<Blob>()
    const [dataTable, setDataTable] = useState<AntiWarmingReport[]>([])
    const [isFetching, setIsFetching] = useState(false)
    const [yearSelectorHeight, setYearSelectorHeight] = useState<number>(0)
    const [yearSelect, setYearSelect] = useState<number>(YEAR_OPTIONS?.[0]?.id || getTime().year)
    const [listSitesSelect, setListSitesSelect] = useState<number[]>(
        caches.getJson(KEY_LOCALSTORAGE.anti_warming) || [],
    )

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const stylesFixed = {
        width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
        transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    }

    const handleCsvCick = () => {
        if (csvData) FileSaver.saveAs(csvData, `reporting-anti-warming-data-table.csv`)
        else console.warn('no data to download')
    }

    function onYearSelected(year: number) {
        setYearSelect(year)
    }

    const fetchData = async (year?: number, sites?: number[]) => {
        try {
            let search = sites && sites?.length > 0 ? sites.join(',') : undefined
            if (search === '0') search = undefined
            let res = await getAntiWarmingReport(search, year)
            if (res)
                if (!listSitesSelect || listSitesSelect.length === 0) {
                    res = res.sort(function (a, b) {
                        return Number(a.site_id) - Number(b.site_id)
                    })
                }
            setDataTable(res)
        } catch (error: any) {
            setDataTable([])
            console.error({ error })
        }
    }

    useEffect(() => {
        fetchData(yearSelect, listSitesSelect)
    }, [yearSelect, listSitesSelect, isFetching])

    return (
        <main>
            <title>レポーティング（温対法）｜ScopeX</title>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 2,
                    top: 70,
                    ...stylesFixed,
                }}
            >
                <Space />
                <ReportingYearSelector
                    onCsvClick={handleCsvCick}
                    setYearSelectorHeight={setYearSelectorHeight}
                    onYearSelected={onYearSelected}
                    year={yearSelect}
                    handleOpen={handleOpen}
                    isAntiWarmingReport={true}
                />
            </div>

            <AntiWarmingTable
                dataTable={dataTable}
                setCsvData={setCsvData}
                yearSelect={yearSelect}
                setIsFetching={setIsFetching}
            />

            {open && (
                <SelectSitesModal
                    open={open}
                    handleClose={handleClose}
                    listSitesSelect={listSitesSelect}
                    setListSitesSelect={setListSitesSelect}
                    keyLocalStorage={KEY_LOCALSTORAGE.anti_warming}
                />
            )}
        </main>
    )
}

const Overview = () => <OverviewInner />

export default withLayout(Overview)
